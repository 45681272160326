<script setup lang="ts">

</script>

<template>
  <div class="bg-accent/60 text-primary rounded-full text-xs py-0.5 font-medium px-2 mb-2 inline-flex">
    <slot></slot>
  </div>
</template>

<style scoped>

</style>
